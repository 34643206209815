/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import PropTypes from 'prop-types';
import Layout from '../components/layout/layout';
import { Fonts, Container, NotFoundPage } from '../global.css';
import BackToTop from '../components/back-to-top/back-to-top';
import axios from 'axios';
import { trialDetailsUrl } from '../constants/config';
import DetailsPage from '../templates/studyDetailsPage';

const NotFound = () => {
  const [canRender, setCanRender] = useState(false);
  const [canRenderStudyDetails, setCanRenderStudyDetails] = useState(false);
  const [studyDetails, setStudyDetails] = useState(Object);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location) {
      const { pathname, href } = window.location;
      if (pathname || href) {
        const match =
          pathname.match(/\/study\/(\w+)/) || href.match(/\/study\/\?id=(\w+)/);
        if (match && match.length) {
          if (match[0].replace('/', '').trim() < 25000) {
            axios
              .get(trialDetailsUrl(`${match[1]}`))
              .then((res) => {
                this.setState({
                  canRenderStudyDetails: true,
                  canRender: true,
                  studyDetails: res.data,
                });
              })
              .catch(() => {
                setCanRender(true);
              });
            return;
          } else {
            axios
              .get(trialDetailsUrl(`${match[1]}`, 'old'))
              .then((res) => {
                this.setState({
                  canRenderStudyDetails: true,
                  canRender: true,
                  studyDetails: res.data,
                });
              })
              .catch(() => {
                setCanRender(true);
              });
            return;
          }
        }
      }
    }
    setCanRender(true);
  }, []);

  return canRender ? (
    studyDetails && canRenderStudyDetails ? (
      <DetailsPage
        location={location}
        data={{
          studyDetails: studyDetails.protocol_form,
          site: data.site,
          studyNotFoundJson: data.studyNotFoundJson,
          trialDetailsJson: data.trialDetailsEnJson,
          isRedirected: true,
        }}
        pageContext={{
          studyId: studyDetails.protocol_form.org_study_id,
        }}
      />
    ) : (
      <Layout>
        <Container>
          <Fonts>
            <NotFoundPage>
              <h1>Page not found</h1>
              <p>If you entered a web address please check it was correct.</p>
              <p>
                You can also try <a href={'/'}>home page</a> to find the
                information you need.
              </p>
            </NotFoundPage>
            <BackToTop />
          </Fonts>
        </Container>
      </Layout>
    )
  ) : null;
};

export default NotFound;

NotFound.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
  navigate: PropTypes.func,
};

export const query = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    studyNotFoundJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    trialDetailsJson {
      ...TrialDetailsJsonFragment
    }
  }
`;
